.back-button {
  color: var(--bs-primary) !important;
  background: var(--headerbg);
  border-radius: 15px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  .ng-fa-icon {
    cursor: pointer;
    font-size: 18px;
  }
}

.danger {
  color: red !important;
}

.create-btn-table {
  padding: 10px;
  border-radius: 15px;
  border: none;
  background-color: rgba(88, 179, 211, 1);
  outline: none;
  span {
    color: white !important;
  }
}
button:disabled {
  opacity: 0.8;
  cursor: no-drop;
}

.ant-btn {
  color: var(--white-black) !important;
  background: var(--headerbg) !important;
  //border: none !important;
}

.ant-btn-primary {
  color: white !important;
  background: var(--bs-primary) !important;
}

.profile-menu-item {
  display: flex;
  align-items: center;
  svg {
    color: var(--bs-primary);
    margin-right: 10px;
  }
}

.ant-btn-dangerous {
  background: #ff4d4f !important;
  color: white !important;
}
.ant-btn-group {
  gap: 3px;
}
