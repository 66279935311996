:root {
  --tui-elevation-01: rgba(242, 243, 246, 1);
}

.modal-header {
  padding: 10px 20px;
  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
  }
  .close {
    cursor: pointer;
    color: rgba(155, 162, 177, 1);
    background-color: var(--headerbg);
    padding: 15px;
    border-radius: 10px;
    border: none;
  }
}

.modal-body {
  padding: 10px 20px;
}

.modal-content, .ant-modal-content {
  box-shadow: 0 0 10px #0000004a;
  background-color: var(--background-color) !important;
  backdrop-filter: blur(10px);
  border-radius: 20px !important;
  border: none;
}

.modal-backdrop,
.t-overlay {
  --bs-backdrop-bg: rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1) !important;
  --bs-backdrop-opacity: 1;
  backdrop-filter: blur(10px) !important;
}

tui-dialog > .t-content {
  backdrop-filter: blur(10px);
  background-color: rgba(242, 243, 246, 1) !important;
  border-radius: 20px !important;
  border: none;
}

.background-gray {
  background-color: rgba(0, 0, 0, 0.1);
}

.container-title {
  font-size: 12px;
  line-height: 14px;
  color: rgba(155, 162, 177, 1);
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 30px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.modal-footer {
  gap: 5px;
  margin-top: 20px;
  padding: 20px;
  border-top: 1px solid rgba(155, 162, 177, 0.1);
}
tui-dialog > .t-content {
  padding: 0 !important;
}

tui-block-status .t-block-text {
  white-space: normal !important;
}

.t-content {
  span {
    //color: var(--white-black);
  }
}

tui-dropdown {
  box-shadow: var(--box-shadow) !important;
  border-color: var(--border-color) !important;
}

.t-empty,
.ant-empty-small {
  color: var(--text) !important;
}

.form-row {
  background-color: var(--headerbg) !important;
  box-shadow: var(--box-shadow) !important;
}

.ant-table-container {
  border: none !important;
}

.modal-container {
  background-color: var(--background-color);
  border-radius: 16px;
}

.ant-table-cell {
  color: var(--white-black) !important;
}

.ant-select-dropdown {
  background-color: var(--headerbg) !important;
}

.ant-drawer-header,
.ant-list-split .ant-list-item {
  border-bottom-color: var(--border-color) !important;
}

.ant-list-bordered {
  border-color: var(--border-color) !important;
}

.ant-modal-body {
  padding: 0 !important;
}

.title {
  color: var(--white-black);
}
