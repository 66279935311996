.pagination {
  padding: 8px 10px;
  background: var(--headerbg) !important;
  border-radius: 10px;
  transition: 0.5s;

  .ant-pagination {
    li {
      border: none !important;

      a {
        color: #9ba2b1 !important;
        transition: 0.5s;
      }

      button {
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--bs-primary) !important;
        font-size: 18px;
      }
    }

    .ant-pagination-disabled {
      button {
        color: #9ba2b1;
      }
    }

    .ant-pagination-item-active {
      background: var(--bs-primary) !important;
      border-radius: 6px;
      transition: 0.5s;

      a {
        color: white !important;
      }
    }
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background-color: var(--background-color) !important;
    transition: 0.5s;
  }

  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    background: none !important;

    .ant-pagination-item-ellipsis {
      color: #9ba2b1;
      transition: 0.5s;
    }
  }

  .ant-pagination-item {
    background: none;
  }

  .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .ant-pagination-item-ellipsis {
      display: flex;
      align-items: center;
    }
  }
}
