.tabs {
  width: 100%;
  border-bottom: 1px solid #9ba2b11a;
  display: flex;
  position: relative;
  padding: 0 5px;
  .tab {
    position: relative;
    margin: 0 5px;
    padding: 15px 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #9ba2b1;
  }
  .active {
    color: var(--tabs-active-color);
  }
  .active::before {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 5px;
    background: #58b3d3;
    border-radius: 4px 4px 0 0;
  }
}
