@font-face {
  font-family: 'Gilroy';
  src: url('https://cdn.jsdelivr.net/gh/repalash/gilroy-free-webfont@fonts/Gilroy-Light.eot');
  src: local('Gilroy Light'), local('Gilroy-Light'),
    url('https://cdn.jsdelivr.net/gh/repalash/gilroy-free-webfont@fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.jsdelivr.net/gh/repalash/gilroy-free-webfont@fonts/Gilroy-Light.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/repalash/gilroy-free-webfont@fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

.create-btn {
  background-color: var(--bs-primary) !important;
  color: white;
  padding: 15px !important;
}

.header-btn {
  height: 200px !important;
  padding: 0 0 0 2px;
  border: 0;
  border-radius: 35px;
  background-color: var(--headerbg);
  display: flex;
  justify-content: space-between;
  margin: 0;
  align-items: center;
  font-family: Gilroy, serif, sans-serif;
  font-size: 20px;
  max-height: 40px;
  outline: none;

  .filter-icon {
    font-size: 45px;
  }

  .filter-text {
    margin-right: 20px;
    font-size: 14px;
    font-family: Gilroy, serif;
    line-height: 16px;
    color: var(--white-black);
    font-weight: 500;
  }

  .filter-vector {
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    border-left: 1px solid #9ba2b11a;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
  }

  .header-text {
    padding: 15px;
    font-size: 14px;
    font-family: Gilroy, serif;
    line-height: 16px;
    color: var(--white-black);
    font-weight: 500;
  }

  .refresh-icon {
    color: #58b3d3;
    margin-left: 15px;
  }
}
.arrow {
  transition: ease-in-out 0.3s;
  transform: rotate(180deg);
}
.arrow-default {
  transition: ease-in-out 0.3s;
  transform: rotate(0deg);
}

tui-input,
tui-input-password,
tui-input-tag {
  width: 100%;
}
.t-text {
  color: white !important;
}
.input-group-ybx {
  display: flex;
  padding: 10px !important;
  display: flex !important;
  // padding-left: 0 !important;
  // padding-right: 0!important;
  margin-bottom: 10px;
}

tui-dropdown {
  z-index: 1000;
}

.w-50 {
  width: calc(50% - 4px) !important;
}

.description {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  margin-left: 20px;
  color: var(--second_text, #9ba2b1);
  margin-top: 10px;
  margin-bottom: 0;
}

.tui-autofill {
  input::placeholder {
    color: var(--tui-text-02) !important;
  }
}

.ant-input {
  background: var(--headerbg) !important;
  color: var(--white-black) !important;
}

.ant-select-item-option-content {
  color: var(--white-black) !important;
}

.ant-select-item-option-selected {
  background: var(--background-color) !important;
  opacity: .8;
}

.ant-select-item:hover, .ant-select-item-option-active {
  background: var(--background-color) !important;
}

.ant-select-selection-item-remove, .ant-select-arrow {
  color: var(--white-black) !important;
}

.ant-select-clear {
  background: var(--headerbg) !important;
  color: var(--white-black) !important;
}

.ant-select-multiple .ant-select-selection-item {
  background: none !important;
}
