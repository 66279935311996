@import 'bootstrap-variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
//@import 'jsoneditor/dist/jsoneditor.min.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import 'ng-zorro/input';
@import 'ng-zorro/button';
@import 'ng-zorro/modal';
@import 'ng-zorro/detail';
@import 'ng-zorro/tabs';
@import 'ng-zorro/loading';
@import 'ng-zorro/table.scss';
@import 'ng-zorro/json-editor.scss';
@import 'ng-zorro/pagination';

* {
  font-family: 'Inter', sans-serif;
}

/* ==============================================================
Bootstrap tweaks
===============================================================*/
body {
  background: var(--background-color);
}

body,
h1,
h2,
h3,
h4,
h5 {
  font-weight: 400;
  color: var(--main-color) !important;
}

.label-required,
.label-optional {
  color: var(--white-black) !important;
}

.label-required::after {
  display: inline-block;
  content: '*';
  color: var(--bs-primary);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.label-optional::after {
  // margin-bottom: 0.5rem;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  content: '(optional)';
  color: rgba(89, 88, 88, 0.69);
  padding: 1px 2px;
  border-radius: 3px;
  font-size: 12px;
  //margin-inline-start: 8px;
}

.text-end {
  text-align: end;
}

/* Increase contrast of links to get 100% on Lighthouse Accessability Audit. Override this color if you want to change the link color, or use a Bootswatch theme */
a {
  color: #533f03;
  font-weight: bold;
}

a:hover {
  color: #533f03;
}

/* override hover color for dropdown-item forced by bootstrap to all a:not([href]):not([tabindex]) elements in _reboot.scss */
a:not([href]):not([tabindex]):hover.dropdown-item {
  color: $dropdown-link-hover-color;
}

/* override .dropdown-item.active background-color on hover */
.dropdown-item.active:hover {
  background-color: mix($dropdown-link-hover-bg, $dropdown-link-active-bg, 50%);
}

a:hover {
  /* make sure browsers use the pointer cursor for anchors, even with no href */
  cursor: pointer;
}

.dropdown-item:hover {
  color: $dropdown-link-hover-color;
}

nz-input-number,
.ant-input-number {
  width: 100% !important;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin: 0 !important;
}

.nowrap {
  flex-wrap: nowrap;
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: 5px;
    background-color: unset;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #595959;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #3b3b3b;
  }
}

/* other generic styles */

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.jh-card {
  padding: 1.5%;
  margin: 20px 0;
  border: none;
}

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.form-control {
  background-color: #fff;

  &:hover,
  &:focus {
    background-color: #fff;
  }
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.hand,
[jhisortby] {
  cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */

.alert-warning {
  display: flex;
  padding: 0 !important;
  color: rgba(159, 96, 0, 0.81);
  border-radius: 15px;
  border: none;

  margin: 0 0 15px 0 !important;
  align-items: center;

  .warning-icon {
    padding: 18px 15px;
    background-color: #eda52e;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    margin: 0 !important;
    color: white;
    font-size: 18px;
  }

  .warning-title {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #feefb3;
    width: 100%;
    padding: 18px 15px;
    margin: 0 !important;
  }
}

.alerts {
  .alert {
    text-overflow: ellipsis;

    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }

    .popover pre {
      font-size: 10px;
    }
  }

  .jhi-toast {
    position: fixed;
    width: 100%;

    &.left {
      left: 5px;
    }

    &.right {
      right: 5px;
    }

    &.top {
      top: 55px;
    }

    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .jhi-toast {
    width: 50%;
  }
}

/* ==========================================================================
entity list page css
========================================================================== */

.table-entities thead th .d-flex > * {
  margin: auto 0;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row-md.jh-entity-details {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .row-md.jh-entity-details > {
    dt {
      float: left;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.5em 0;
    }

    dd {
      border-bottom: 1px solid #eee;
      padding: 0.5em 0;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-menu {
  padding-left: 0px;
  z-index: 999;
}

/* ==========================================================================
angular-cli removes postcss-rtl processed inline css, processed rules must be added here instead
========================================================================== */
/* page-ribbon.component.scss */
.ribbon {
  left: -3.5em;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* navbar.component.scss */
.navbar {
  ul.navbar-nav {
    .nav-item {
      margin-left: 0.5em;
    }
  }
}

/* jhipster-needle-scss-add-main JHipster will add new css style */

.navbar-collapse {
  flex-grow: 0;
}

.modal-backdrop {
  z-index: 400 !important;
}

.modal {
  z-index: 500 !important;
}

.ant-btn {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center;
  border-radius: 25px !important;
}

.ant-btn-primary {
  background-color: var(--bs-primary) !important;
  border-color: #58b3d333 !important;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  //margin: 0 !important;
}

.ant-input {
  border-radius: var(--border-radius) !important;
  width: 100%;
}

.ant-select {
  width: 100%;
  color: var(--white-black) !important;
}

.ant-select-selector {
  border-radius: var(--border-radius) !important;
  width: 100%;
  background-color: var(--headerbg) !important;
}

.ant-picker {
  border-radius: var(--border-radius) !important;
  width: 100%;
}

.ant-input-number {
  border-radius: var(--border-radius) !important;
  width: 100%;
}

.anticon {
  //color: white !important;
  //width: 32px;
  //height: 32px;
  margin: 0 5px;
  //font-size: 14px;
  display: flex !important;
  align-items: center;

  svg {
    //width: 20px !important;
    //height: 20px !important;
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline-block !important;
  vertical-align: middle !important;
}

.btn-close {
  display: none !important;
}

.gap-4 {
  gap: 4px;
}

.ng-fa-icon {
  margin-right: 4px;
}

.btn-group {
  .ant-btn {
    border-radius: 1px !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.c-black {
  color: #000000 !important;
}

.disabled-background {
  color: #00000040;
  background: #f5f5f5 !important;
  cursor: not-allowed !important;
  border: 1px solid #d9d9d9 !important;
}

.ant-upload-drag-icon {
  display: flex;
  justify-content: center;
}

.ant-select-clear {
  width: 20px !important;

  .ant-select-close-icon {
    width: 10px;
    margin-right: 15px;
  }
}

.ant-table {
  border-radius: 15px !important;
  background: none !important;
}

.ant-table-footer,
.ant-table-thead > tr > th {
  background: none !important;

  span,
  sort-icon {
    display: flex;
    align-items: center;
    //justify-content: center;
    color: #9ba2b1;
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: var(--hover-color) !important;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafcff !important;
}

.ant-input-number-borderless {
  border: none !important;
}

.container {
  width: 80%;
  max-width: 80%;
}

.body::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* scrollbar itself */
.body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 10px;
  // border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.body::-webkit-scrollbar-button {
  display: none;
}

.container-fluid {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
}

.container-white {
  background: var(--headerbg);
  border-radius: 20px;
  box-shadow: var(--box-shadow) !important;
}

.color-primary {
  color: var(--bs-primary) !important;
}

.ng-star-inserted {
  tui-icon {
    cursor: pointer;
  }

  .openView {
    max-width: 200px;
    text-align: center;
    border-radius: 8px;
    padding: 2px;
    border: 1px solid #58b3d3;
    background-color: rgba(88, 179, 211, 0.16);
    cursor: pointer;
    transition: ease-in-out 0.2s;

    a {
      color: #58b3d3 !important;
    }

    &:hover {
      background-color: rgba(62, 138, 204, 0.15);
    }
  }
}

.add-field-btn {
  height: 56px !important;
  border-radius: 12px !important;
}

#page-heading {
  padding-top: 10px;
}

[tuiWrapper][data-appearance='primary'] {
  background-color: var(--bs-primary) !important;
}

.table-responsive,
.ed-table-body {
  background-color: var(--headerbg) !important;
  border-radius: 20px;
  transition: 0.5s;
  border: none !important;
  box-shadow: var(--box-shadow) !important;

  td {
    transition: 0.5s;
    color: var(--white-black) !important;
    border-bottom: 1px solid var(--border-color) !important;
  }

  th {
    border-bottom: 1px solid var(--border-color) !important;
    transition: 0.5s;
  }
}

.list-save {
  color: #58b3d3 !important;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(88, 179, 211, 0.16) !important;
  border: 0;
}

.ant-list {
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for webkit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.ant-list-item {
  color: var(--white-black) !important;
}

.ant-collapse,
.ant-collapse-content,
.ant-input-group-addon {
  background-color: var(--background-color) !important;
  color: var(--white-black) !important;
}

.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  color: var(--white-black);
}

.ant-notification-notice {
  background: var(--headerbg) !important;
}

.ant-notification-notice-message {
  color: var(--white-black) !important;
}

.ant-notification-notice-description {
  color: var(--white-black) !important;
}

.ant-notification-notice-close {
  color: var(--text) !important;
}

.filterBtn {
  color: white !important;
}

.filterBtn {
  color: white !important;
  background-color: var(--bs-primary) !important;
}

.ant-tabs {
  color: #9ba2b1 !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid rgba(155, 162, 177, 0.1019607843) !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--white-black) !important;
}

.t-root-content {
  height: 10vh;
}

.ant-upload {
  background-color: var(--background-color) !important;
  border-color: var(--border-color);

  .ng-star-inserted {
    color: var(--white-black) !important;
  }
}

.tui-space_top-4 * {
  font-family: 'Roboto', sans-serif !important;

  letter-spacing: 1px;
  color: var(--white-black) !important;
}

.validate-warning {
  color: var(--white-black);
}

.tariff-type {
  tui-segmented[data-size='s'] {
    height: 48px !important;
    color: var(--background-color-tariff-type);
  }

  width: 248px;

  button {
    padding: 0 25px;

    span {
      font-weight: 600;
      font-family: 'Manrope', sans-serif;
      letter-spacing: 0.5px;
    }
  }

  .tariff-type-icon {
    font-size: 17px;
  }
}

button:disabled {
  opacity: 0.5;
  background: #00000026 !important;
  cursor: not-allowed !important;
}

.field-error-text {
  font-size: 12px;
  color: var(--badge-txt-color-check-error);
  margin-bottom: 0;
  margin-left: 1rem;
}

.ant-message-custom-content {
  display: flex;
}
.excel-btn-icon {
  font-size: 18px;
}
.excel-btn {
}
