.badge {
  color: white;
  //width: 76px;
  height: 24px;
  padding: 4px 8px;
  text-align: center;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
}

.badge-deleted {
  color: var(--badge-txt-color-deleted);
  background: var(--badge-bg-color-deleted);
}

.badge-waiting {
  color: var(--badge-txt-color-waiting);
  background: var(--badge-bg-color-waiting);
}

.badge-paid {
  color: var(--badge-txt-color-paid);
  background: var(--badge-bg-color-paid);
}

.badge-denied {
  color: var(--badge-txt-color-denied);
  background: var(--badge-bg-color-denied);
}
.badge-new {
  color: var(--badge-txt-color-new);
  background-color: var(--badge-bg-color-new);
}
.badge-blocked {
  color: var(--badge-txt-color-blocked);
  background-color: var(--badge-bg-color-blocked);
}
.badge-disabled {
  color: var(--badge-txt-color-disabled);
  background-color: var(--badge-bg-color-disabled);
}
.badge-checking {
  color: var(--badge-txt-color-checking);
  background-color: var(--badge-bg-color-checking);
}
.badge-canceled {
  color: var(--badge-txt-color-canceled);
  background-color: var(--badge-bg-color-canceled);
}
.badge-failed {
  color: var(--badge-txt-color-failed);
  background-color: var(--badge-bg-color-failed);
}
.badge-invoice-sending {
  color: var(--badge-txt-color-invoice-sending);
  background: var(--badge-bg-color-invoice-sending);
}
.badge-invoice-send {
  color: var(--badge-txt-color-invoice-send);
  background: var(--badge-bg-color-invoice-send);
}
.badge-closed {
  color: var(--badge-txt-color-closed);
  background: var(--badge-bg-color-closed);
}
.badge-success {
  color: var(--badge-txt-color-success);
  background: var(--badge-bg-color-success);
}
.badge-check-ok {
  color: var(--badge-txt-color-check-ok);
  background-color: var(--badge-bg-color-check-ok);
}
.badge-check-unknown {
  color: var(--badge-txt-color-unknown);
  background-color: var(--badge-bg-color-unknown);
}
.badge-check-error {
  color: var(--badge-txt-color-error);
  background-color: var(--badge-bg-color-error);
}
