.ant-table-tbody > tr > td {
  border-color: var(--border-color) !important;
  background: none !important;
}

.ant-table-tbody > tr:hover {
  background-color: var(--table-hover-color) !important;
}

.ant-table-row-expand-icon {
  background: none !important;
}

.ant-table-footer span, .ant-table-footer sort-icon, .ant-table-thead > tr > th span, .ant-table-thead > tr > th sort-icon {
  display: inline-block !important;
}

sort-icon {
  float: none !important;
}
