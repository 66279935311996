/* after changing this file run 'npm run webapp:build' */

/***************************
put Sass variables here:
eg $input-color: red;
****************************/
// Override Bootstrap variables
@import './bootstrap-variables';
// Import Bootstrap source files from node_modules
@import 'bootstrap/scss/bootstrap';

/* jhipster-needle-scss-add-vendor JHipster will add new css style */
@import './nz-switch';
@import './badge';
