@import 'bootstrap-variables';

.ant-switch {
  height: 30px !important;
  line-height: 30px !important;
  border-radius: $border-radius !important;
}

.ant-switch-handle {
  top: 4px !important;
  left: 4px !important;
  width: 21px !important;
  height: 21px !important;
}

.ant-switch-inner {
  margin: 0 12px 0 32px !important;
  font-size: $font-size-sm !important;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 24px - 2px) !important;
}

.ant-switch-checked .ant-switch-inner {
  margin: 0 32px 0 12px !important;
}

.ant-switch-handle::before {
  border-radius: $border-radius !important;
}
