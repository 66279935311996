.jsoneditor-tree {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.jsoneditor {
  border-radius: 12px;
}
.jsoneditor-menu {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.jsoneditor-search {
  right: 8px;
}
.jsoneditor-text {
  border-radius: 10px;
}
