.container-title-inside {
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
}

.entity-detail {
  padding: 20px 10px;
}

.container-gray {
  background: var(--background-color);
  padding: 20px 20px;
  border-radius: 20px;
  margin: 10px;
  width: 100%;
  div {
    display: flex;
    margin: 10px 0;
    height: 40px;
    span:nth-child(1) {
      color: var(--text);
      min-width: 200px;
    }
    span:nth-child(2) {
      color: var(--white-black);
      border-bottom: 1px solid #9ba2b11a;
      padding-bottom: 10px;
      width: 100%;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      height: 30px;
    }
  }
}
